import React from 'react';
import Body from './components/Body/Body';

export default function App() {
  return (
    <div>
      <Body />
    </div>
  );
}
